import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import { slugify } from "../utils/string"

const TagsPage = ({
  location,
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout location={location} title={title}>
    <Seo title={`Categorías`} description="Categorías de nuestros artículos" />
    <div>
      <h1>Categorías</h1>
      <p>
        Nuestros artículos están clasificados según las siguientes categorías:
      </p>
      <ul>
        {group.map(tag => (
          <li key={tag.tagName}>
            <Link to={`/tags/${slugify(tag.tagName)}/`}>{tag.tagName}</Link>
          </li>
        ))}
      </ul>
    </div>
  </Layout>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          tagName: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        tagName: fieldValue
        totalCount
      }
    }
  }
`
